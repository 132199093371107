/* Basic styling for the upload page */
.upload-page {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .upload-page h1 {
    text-align: center;
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .upload-page form {
    display: flex;
    flex-direction: column;
  }
  
  .upload-page label {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
  }
  
  .upload-page input[type="text"],
  .upload-page input[type="file"],
  .upload-page textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .upload-page textarea {
    resize: vertical;
    height: 100px;
  }
  
  .upload-page button[type="submit"] {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .upload-page button[type="submit"]:hover {
    background-color: #218838;
  }
  
  .upload-page .error-message {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
  }
  
  .upload-page .success-message {
    color: green;
    margin-bottom: 40px;
    font-size: 39px;
    text-align: center;
  }
  
  .upload-page .preview-image {
    max-width: 50%;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .upload-page .btn-upload {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-page .btn-upload:hover {
    background-color: #0069d9;
  }
  .btn-logout {
    margin-top: 20px;
    padding: 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-logout:hover {
    background-color: #ff3333;
  }
  