/* Header.css */
.header {
    background-color: #14213d;
    color: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed; 
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease; 
}

.logo h1 {
    margin: 0;
    font-size: 1.8rem;
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav ul li {
    margin: 0 1.5rem;
}

.nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease, transform 0.3s ease; 
}

.nav ul li a:hover {
    color: #fca311; 
    transform: translateY(-2px); 
}

/* Hamburger Button */
.hamburger {
    display: none; 
    font-size: 2.8rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute; /* Positioning the hamburger */
    top: 1rem; /* Adjust as needed */
    right: 6rem; /* Adjust as needed */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .nav ul {
        flex-direction: column; 
        position: absolute;
        background-color: #14213d;
        top: 100%;
        left: 0;
        width: 100%;
        display: none; 
        z-index: 999;
    }

    .nav.active ul {
        display: flex; 
    }

    .nav ul li {
        margin: 1rem 0; 
        text-align: center;
    }

    .hamburger {
        display: block; 
    }
}

/* Logo Image Styles */
.logo-image {
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-image:hover {
    transform: scale(1.1); 
}
.hamburger:hover {
    transform: scale(1.1); 
}
