/* General About section styling */
.about {
  padding: 2rem;
  background-color: #f9f9f9;
}

.certificates {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.certificate-item img {
  width: 100%;
  max-width: 250px;  /* Limits the image size for mobile */
  height: auto;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Reviews Section Styling */
.reviews-section {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.reviews-section h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.reviews-section p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

.reviews-embed {
  margin-bottom: 1rem;
}

.reviews-embed iframe {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* More reviews link */
.more-reviews {
  text-align: center;
  margin-top: 1rem;
}

.more-reviews a {
  color: #0066cc;
  text-decoration: none;
  font-size: 1.1rem;
}

.more-reviews a:hover {
  text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .certificates {
    flex-direction: column;
    align-items: center;
  }
  
  .certificate-item img {
    max-width: 90%; /* Adjusts image size on mobile devices */
  }
  
  .reviews-embed iframe {
    height: 300px;
  }
}
