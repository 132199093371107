/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homepage {
  font-size: 16px;
}

/* Hero Section */
.hero {
  background-image: url('https://res.cloudinary.com/dmdyf4fsn/image/upload/v1726862735/vyiohg64yceoorpcz1h6.jpg'); /* Ensure to replace with a high-quality background image */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  animation: fadeIn 2s ease-in-out;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin: 1rem 0 2rem 0;
  animation: fadeIn 2.5s ease-in-out;
}

.cta-button {
  padding: 0.75rem 2rem;
  background-color: #fca311;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e88b10;
}

/* Fade-in Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* About Section */
.about {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #f9f9f9;
}

.about h2 {
  font-size: 2.5rem;
  color: #333;
}

.about p {
  max-width: 800px;
  margin: 1rem auto;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.7;
}

/* Services Section */
.services {
  padding: 4rem 2rem;
  background-color: white;
}

.services h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.5rem;
  color: #333;
}

.service-card p {
  font-size: 1rem;
  color: #666;
}

/* Testimonial Section */
.testimonials {
  padding: 4rem 2rem;
  background-color: #f1f1f1;
  text-align: center;
}

.testimonials h2 {
  font-size: 2.5rem;
  color: #333;
}

.testimonial-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.testimonial-card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
}

.testimonial-card h4 {
  margin-top: 1rem;
  font-weight: bold;
}

/* Call to Action Section */
.cta-section {
  padding: 4rem 2rem;
  background-color: #fca311;
  text-align: center;
  color: white;
}

.cta-section h2 {
  font-size: 2.5rem;
}

.cta-section p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.cta-button {
  padding: 0.75rem 2rem;
  background-color: #14213d;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0f192c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .services-list {
    grid-template-columns: 1fr;
  }

  .testimonials {
    padding: 2rem;
  }

  .cta-section h2 {
    font-size: 2rem;
  }
}

/* Media queries for even smaller screens */
@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
  }

  .service-icon {
    font-size: 2rem;
  }
}
