.services-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .services-intro {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .service-card {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    color: #666;
  }
  
  .service-contact {
    text-align: center;
    margin-top: 3rem;
  }
  
  .service-contact a {
    color: #007bff;
    text-decoration: none;
  }
  
  .service-contact a:hover {
    text-decoration: underline;
  }
  