/* Grid container styling */
.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 30px; /* Space between items */
  padding: 20px;
  justify-items: center; /* Center items horizontally */
}

/* Individual Product Styling */
.portfolio-item {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image container styling */
.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Aspect ratio: 4:3 */
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures image fits within container without cropping */
  transition: transform 0.3s ease;
}

.portfolio-item:hover .product-image {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Text Styling */
.portfolio-item h3 {
  margin: 15px 0 10px;
  font-size: 1.2rem;
  text-align: center;
  color: #333;
}

.portfolio-item p {
  font-size: 1rem;
  color: #666;
  padding: 0 15px 15px;
  text-align: center;
}

/* Skeleton Loader Styling */
.skeleton {
  background-color: #ddd;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}
