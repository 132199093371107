/* Footer.css */
.footer {
    background-color: #14213d; /* Align with header color */
    color: #fca311; /* Change text color for contrast */
    padding: 0.5rem 1rem; /* Reduced padding for a lower height */
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px; /* Centered content */
    margin: 0 auto;
  }
  
  .footer-content p {
    margin: 0.2rem 0; /* Reduced margin for compactness */
    font-size: 0.9rem; /* Smaller font for a cleaner look */
  }
  
  .social-media {
    margin: 0.5rem 0; /* Reduced margin for compactness */
  }
  
  .social-media a {
    margin: 0 0.5rem; /* Reduced margin for compactness */
    color: #fca311; /* Match footer text color */
    text-decoration: none;
    transition: color 0.3s ease; /* Transition effect */
  }
  
  .social-media a:hover {
    color: #fff; /* Lighter color on hover */
  }
  